/**
 * The GWG confirmation component
 * This component is actually used only on the remote side of the signing processs.
 * @todo LEASA-XXXX:
 * - Replace 'standard-confirmation' for on-site confirmation
 */

// #region Imports

import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ConfirmationDocumentConfig } from '../../../../../../../apps/shell/src/app/models/ConfirmationDocumentConfig.interface';
import { UsedCondition } from '../../../../../../../apps/shell/src/app/models/enums/UsedCondition.enum';
import { ActivatedRoute, Router } from '@angular/router';
import {
    IContractTypeDto,
    IObjectConditionDto,
    ISaleAndLeaseBackCodeDto,
    ISaleAndLeaseBackDto,
} from '@abcfinlab/api/global';
import { BehaviorSubject, Observable } from 'rxjs';

// #endregion

/**
 * @public
 */
@UntilDestroy()
@Component({
    selector: 'gwg-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent implements OnInit {

    private readonly _formBuilder: UntypedFormBuilder;
    private readonly _route: ActivatedRoute;
    private readonly _router: Router;
    private readonly _cdr: ChangeDetectorRef;
    private readonly _hasSlbConfirmation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    public constructor(formBuilder: UntypedFormBuilder, route: ActivatedRoute, router: Router, cdr: ChangeDetectorRef) {
        this._formBuilder = formBuilder;
        this._route = route;
        this._router = router;
        this._cdr = cdr;
    }

    /**
   * @public
   */
    @Input()
    public condition: UsedCondition | IObjectConditionDto;

    /**
   * @public
   */
    @Input()
    public options: ConfirmationDocumentConfig;

    /**
     * @public
     */
    @Input()
    public slbInfo: ISaleAndLeaseBackDto;

    @Input() contractType: IContractTypeDto;

    /**
   * @public
   */
    @Output()
    public validated = new EventEmitter<'VALID' | 'INVALID' | 'PENDING' | 'DISABLED'>();

    /**
   * @internal
   * @hidden
   */
    public confirmationGroup: UntypedFormGroup;

    public get hasSlbConfirmation(): Observable<boolean> {
        return this._hasSlbConfirmation.asObservable();
    }

    /**
   * Open the corresponding document-file within the pdf document viewer component
   * @param documentType  The document typ that should be loaded within the pdf viewer
   */
    public openDocumentViewer(documentOption: ConfirmationDocumentConfig, event: MouseEvent): void {
        event.preventDefault();
        const url = this._router.serializeUrl(
            this._router.createUrlTree([documentOption], {
                relativeTo: this._route,
            }),
        );
        window.open(url, '_blank');
        event.stopPropagation();
    }

    /**
   * @public
   */
    public ngOnInit(): void {
        this._hasSlbConfirmation.next(this.showSlbConfirmation(this.slbInfo?.code, this.slbInfo?.completed));
        this.confirmationGroup = this._formBuilder.group({
            gdprConfirmation: [false, Validators.requiredTrue],
            agbConfirmation: [false, Validators.requiredTrue],
            sepaConfirmation: [false, Validators.requiredTrue],
            eowConfirmation: [false, this.condition === UsedCondition.USED ? [Validators.requiredTrue] : null],
            slbConfirmation: [false, this._hasSlbConfirmation.getValue() ? [Validators.requiredTrue] : null],
        });

        this.confirmationGroup.statusChanges
            .pipe(
                untilDestroyed(this),
            ).subscribe(x => this.validated.emit(x));
        this._cdr.detectChanges();
    }

    private showSlbConfirmation(code: ISaleAndLeaseBackCodeDto, isCompleted: boolean): boolean {
        if ((code === ISaleAndLeaseBackCodeDto.UnechtliV2 || code === ISaleAndLeaseBackCodeDto.UnechtlnV2 || code === ISaleAndLeaseBackCodeDto.Unechtlnmk || code === ISaleAndLeaseBackCodeDto.Unechtlimk) && isCompleted) {
            return true;
        } else if (code && code !== ISaleAndLeaseBackCodeDto.UnechtliV2 && code !== ISaleAndLeaseBackCodeDto.UnechtlnV2 && code !== ISaleAndLeaseBackCodeDto.Unechtlnmk && code !== ISaleAndLeaseBackCodeDto.Unechtlimk) {
            return true;
        }

        return false;
    }

}
