import { SlbCodeFormDTO } from '../../../../../../apps/shell/src/app/models/CalculationFormDTO';
import { UsedCondition } from '../../../../../../apps/shell/src/app/models/enums/UsedCondition.enum';
import { LeasingQuoteObjectDTO } from '../../../../../../apps/shell/src/app/models/LeasingQuoteObjectDTO.interface';
import { ConfirmationType } from './confirmation-type.enum';
import { IObjectDto } from '@abcfinlab/api/global';

export class ConfirmationDTO {

    public constructor(
        private readonly leasing_quote_id: string,
        private readonly leasing_quote_confirmation_types: Array<ConfirmationType>,
    ) {
    }

    static createStandardConfirmations(leasingQuoteId: string,
        leasingQuoteObjectDTO: LeasingQuoteObjectDTO | IObjectDto,
        saleAndLeaseBack: SlbCodeFormDTO): ConfirmationDTO {
        const confirmations: Array<ConfirmationType> = [
            ConfirmationType.SEPA,
            ConfirmationType.TERMS_AND_CONDITIONS,
            ConfirmationType.GDPR,
        ];

        if (saleAndLeaseBack?.active) {
            confirmations.push(ConfirmationType.SALE_AND_LEASE_BACK);
        }

        if (leasingQuoteObjectDTO.condition === UsedCondition.USED) {
            confirmations.push(ConfirmationType.USED_CONDITION);
        }
        return new ConfirmationDTO(leasingQuoteId, confirmations);
    }

    static createNaturalPersonConfirmation(leasingQuoteId: string): ConfirmationDTO {
        return new ConfirmationDTO(leasingQuoteId, [ConfirmationType.IDENTIFICATION_NATURAL_PERSON, ConfirmationType.PERSON_ON_OWN_BEHALF]);
    }

    static createJuristicPersonConfirmation(leasingQuoteId: string, transparencyRegisterType: ConfirmationType): ConfirmationDTO {
        return new ConfirmationDTO(leasingQuoteId, [ConfirmationType.IDENTIFICATION_JURISTIC_PERSON, transparencyRegisterType]);
    }

}
