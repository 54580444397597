import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'gwg-natural-person-confirmation',
    templateUrl: './natural-person-confirmation.component.html',
    styleUrls: ['./natural-person-confirmation.component.scss'],
})
export class NaturalPersonConfirmationComponent implements OnInit {

    conclusionForm: UntypedFormGroup;

    @Output() valid: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(
        private readonly fb: UntypedFormBuilder,
    ) { }

    ngOnInit() {
        this.conclusionForm = this.fb.group({
            successfulIdentificationNaturalPerson: false,
            checkPersonalConfirmation: '',
        });

        this.conclusionForm.valueChanges
            .pipe(untilDestroyed(this))
            .subscribe(values => this.checkState(values));
    }

    private checkState(state: any): void {
        if (this.validState(state)) {
            this.valid.emit(true);
        } else {
            this.valid.emit(false);
        }
    }

    private readonly validState: (state: any) => boolean = (state: any) =>
        state.successfulIdentificationNaturalPerson
        && state.checkPersonalConfirmation === 'confirm';

}
