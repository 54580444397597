import { Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { RemoteContactDTO } from '../../../../../../../apps/shell/src/app/models/RemoteContactDTO.interface';
import { BreakpointService, DevicesByWidth } from '../../../../../../../libs/layout/src/Services/BreakpointService';
import { RemoteState } from '../../../../../../../apps/shell/src/app/state/Remote.state';

@UntilDestroy()
@Component({
    selector: 'gwg-signature-remote-container',
    template: `
    <div class="page-container" style="display: flex; flex-direction: column;">
    <gwg-sales-lead-information [salesLead]="salesInformation$ | async"></gwg-sales-lead-information>
    <gwg-signature [isRemote]="true"
                   [signaturePadWidth]="signaturePadWidth"
                   [signingLinkUui]="signingLinkUui$ | async">
    </gwg-signature>
  </div>`,
    styles: [
        `
                                                                                  gwg-sales-lead-information {
                                                                                    float: right;
                                                                                  }
                                                                                `,
    ],
})
export class SignatureRemoteContainerComponent {

    public salesInformation$: Observable<RemoteContactDTO>;

    public signingLinkUui$: Observable<string>;

    signaturePadWidth: number;

    constructor(private readonly _breakingPoint: BreakpointService, _store: Store) {
        this.salesInformation$ = _store.select(RemoteState.getSalesInformation);
        this.signingLinkUui$ = _store.select(RemoteState.getSigningLinkUui);

        this._breakingPoint.deviceTypeByWidth$
            .pipe(untilDestroyed(this))
            .subscribe((type) => {
                switch (type) {
                    case DevicesByWidth.XSMALL:
                        this.signaturePadWidth = 350;
                        break;
                    case DevicesByWidth.SMALL:
                        this.signaturePadWidth = 700;
                        break;
                    case DevicesByWidth.MEDIUM:
                        this.signaturePadWidth = 900;
                        break;
                    case DevicesByWidth.LARGE:
                        this.signaturePadWidth = 1024;
                        break;
                    default:
                        this.signaturePadWidth = 1024;
                }
            });
    }

}
